/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react'
import { BaseButton } from 'iebt-zing-storybook'
import {
  ButtonBox,
  ChallengeName,
  Dot,
  IdeaImage,
  IdeaTile,
  LastContainer,
  ProgramName,
  Status,
  TextContainer,
  Title,
  IdeaImageWrapper
} from './styles'
import { useNavigate } from 'react-router'
import { getBtnName, getIdeaImage, setStatusColor } from './behavior'
import { trans } from 'sharedKernel/i18n'
import { Container, Row, Col } from 'react-bootstrap'

export const IdeaAllTile = ({ idea, ...props }: any): JSX.Element => {
  const navigate = useNavigate()
  const ideaImage = getIdeaImage(idea.challenge.style.coverImagePath)
  const statusColor = setStatusColor(idea.status.name, idea.fillPercentage)
  const btnName = getBtnName(idea.isLaunched, idea.fillPercentage)
  const statusName = trans(idea.status.name)

  return (
    <Container fluid>
      <Row className="p-0 bg-white rounded">
        <Col xs={3} lg={2} className="p-0 p-sm-2">
          <IdeaImageWrapper>
            <IdeaImage src={ideaImage} />
          </IdeaImageWrapper>
        </Col>
        <Col xs={6} lg={8} className="pl-3">
          <TextContainer>
            <Title>{idea.title}</Title>
            <ChallengeName>{idea.challenge.config.title}</ChallengeName>
            <ProgramName>{idea.challenge.config.program.name}</ProgramName>
          </TextContainer>
        </Col>
        <Col xs={3} lg={2} className="p-0">
          <LastContainer>
            <ButtonBox>
              <BaseButton
                btnContent={btnName}
                variant="risk"
                size="medium"
                onClick={() =>
                  navigate(`/hub/startup/idea/${String(idea.id)}/challenge/${String(idea.challenge.id)}`)
                }
              />
            </ButtonBox>
            <Status>
              <Dot bgColor={statusColor} />
              {idea.isLaunched === true
                ? `Status: ${statusName}`
                : `${parseInt(idea.fillPercentage) ?? '0'}% preenchido`}
            </Status>
          </LastContainer>
        </Col>
      </Row>
    </Container>
  )
}
