/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useLocation } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab, faTwitterSquare, faFacebook, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { BaseButton } from 'iebt-zing-storybook'
import Tippy from '@tippyjs/react'
import { trans } from 'sharedKernel/i18n'
import { VisualizationScreenProps } from './contract'
import { ScreenContainer } from './styles'
import { RemoveChallengeButton } from './Buttons/RemoveChallengeButton'
import { EditChallengeButton } from './Buttons/EditChallengeButton'
import { EvaluatorsButton } from './Buttons/EvaluatorsButton'
import { LaunchChallengeButton } from './Buttons/LaunchChallengeButton'
import { LaunchIdeaButton } from './Buttons/LaunchIdeaButton'
import { Loading } from 'components/loading'
import { NoContent } from 'components/NoContent'
import { Timeline } from 'components/Timeline'
import { SolutionsButton } from './Buttons/SolutionsButton'
import { isCurrentDateBiggerThanDeadline, isStartDateGreaterThanCurrentDate } from 'sharedKernel/handlers'
import {
  AttachmentsArea,
  AttachmentsItem,
  AttachmentsTitle,
  CoverImage,
  DescriptionText,
  DescriptionTitle,
  IdeasLimit,
  PresentationArea,
  PresentationVideo,
  Title
} from 'sharedKernel/styledComponents/visualizationComponents/styles'
import { isUserOwner } from './behavior'

library.add(fab, faTwitterSquare, faFacebook, faYoutube, faInstagram)

export const VisualizationScreen = ({
  isFetching,
  preview = false,
  data,
  isFileFetching,
  fileData
}: VisualizationScreenProps): JSX.Element => {
  const filesChallenge = {
    challengeFiles: fileData?.filter((file) => file.type === 'supportFiles'),
    homePageSlider: fileData?.find((file) => file.type === 'homePageSlider'),
    presentationVideo: fileData?.find((file) => file.type === 'presentationVideo'),
    coverImage: fileData?.find((file) => file.type === 'coverImage')
  }

  const { state }: any = useLocation()

  const labels = {
    notFound: trans('Não encontrado'),
    description: trans('Descrição'),
    attachments: trans('Anexos'),
    supportFilesNotFound: trans('Nenhum arquivo de apoio foi incluído neste desafio'),
    closed: trans('Encerrado'),
    solutionLimit: trans('Limite de soluções atingido'),
    signUpSoon: trans('Inscreva-se em breve'),
    tooltipYoutubeIcon: trans('Vídeo de apresentação')
  }

  if (isFetching ?? false) {
    return (
      <Loading />
    )
  }

  if (data === undefined) {
    return <NoContent text={labels.notFound} />
  }

  const isClosed = isCurrentDateBiggerThanDeadline(data.data.config.step.finalSelectionDate)
  const startDateNotReached = isStartDateGreaterThanCurrentDate(data.data.config.step.applicationStartDate)

  const isSolutionsLimitAchieved = (): boolean => {
    const hasNumberOfSolutions = data.data.config.numberOfSolutions !== null

    if (!hasNumberOfSolutions) {
      return false
    }

    if (preview) {
      return false
    }

    return data.data.config.numberOfSolutions >= data.data.config.limitOfIdeas
  }

  const ownerId = data.data.owner.id
  const isOwner = isUserOwner(ownerId)
  const challengeId = data.id
  let isLaunched = data.data.isLaunched
  const youtubeLink = data.data.style.youtubeLink
  const remainingAttempts = data.data.config.limitOfIdeas - data.data.config.numberOfIdeas
  const solutionsLimitAchieved = isSolutionsLimitAchieved()

  if (state !== null) {
    isLaunched = state.setToLaunched
  }

  const getIdeaImage = (url?: string): string => {
    const fallBackIdeaImage = require('../../../../assets/images/Imagem_do_programa.png')

    return url != null && url.length > 0 ? url : fallBackIdeaImage
  }

  const getSubscribeButton = (): JSX.Element => {
    if (isClosed) {
      return (
        <IdeasLimit>{labels.closed}</IdeasLimit>
      )
    }

    if (solutionsLimitAchieved) {
      return (
        <IdeasLimit>{labels.solutionLimit}</IdeasLimit>
      )
    }

    if (startDateNotReached) {
      return (
        <IdeasLimit>{labels.signUpSoon}</IdeasLimit>
      )
    }

    return (
      <LaunchIdeaButton challengeId={challengeId} preview={preview} remainingAttempts={remainingAttempts} />
    )
  }

  return (
    <ScreenContainer>
      <CoverImage backgroundImg={getIdeaImage(filesChallenge?.homePageSlider?.path)} />
      <Row>
        <Col sm={4} xxl={2}>
          <Row>
            {!isLaunched ? (
              <Col sm={12} className="gy-4">
                <LaunchChallengeButton challengeId={challengeId} isOwner={isOwner} alreadyLaunched={isLaunched} />
              </Col>
            ) : (
              <Col sm={12} className="gy-3">
                <EvaluatorsButton challengeId={challengeId} isOwner={isOwner} alreadyLaunched={isLaunched} />
                <SolutionsButton challengeId={challengeId} isOwner={isOwner} alreadyLaunched={isLaunched} />
                {getSubscribeButton()}
              </Col>
            )}
            <Col sm={12} className="gy-3">
              {youtubeLink !== '' ? (
                <Tippy
                content={labels.tooltipYoutubeIcon}
                animation='scale'
                arrow={false}
                maxWidth="100%"
                theme='primary'
                allowHTML={true}
              >
                <a href={youtubeLink} target="_blank">
                    <BaseButton
                      btnContent={<FontAwesomeIcon icon={['fab', 'youtube']} size="xl" />}
                      variant="primary-invert"
                      size="icon"
                    />
                </a>
            </Tippy>
              ) : (
                <></>
              )}
            </Col>
            <Col sm={12} className="gy-4">
              <AttachmentsArea>
                <AttachmentsTitle>{labels.attachments}:</AttachmentsTitle>
                {!isFileFetching && filesChallenge.challengeFiles !== undefined ? (
                  filesChallenge.challengeFiles.map((file, index) => {
                    return (
                      <AttachmentsItem key={`attachments-item-${index}`}>
                        <a key={`item-${index}`} href={file.path} target="_blank" download={file.name}>
                          {file.name}
                        </a>
                      </AttachmentsItem>
                    )
                  })
                ) : (
                  <AttachmentsItem>{labels.supportFilesNotFound}</AttachmentsItem>
                )}
              </AttachmentsArea>
            </Col>
          </Row>
        </Col>
        <Col sm={8} xxl={10}>
          <Row>
            <Col sm={isOwner ? 10 : 12}>
              <Title>{data.data.config.title}</Title>
            </Col>
            <Col sm={2} className="d-flex align-items-center justify-content-end gap-2">
              <EditChallengeButton challengeId={data.id} isOwner={isOwner} alreadyLaunched={isLaunched} />
              <RemoveChallengeButton challengeId={data.id} isOwner={isOwner} alreadyLaunched={isLaunched} />
            </Col>
            <Col sm={12} className="gy-4">
              <Timeline steps={data.data.config.step} />
            </Col>
            <Col sm={12} className="gy-4">
              <DescriptionTitle>{labels.description}</DescriptionTitle>
              <hr />
              <DescriptionText>
                {data.data.style.shortDescription}
                <br />
                {data.data.style.longDescription}
              </DescriptionText>
              <PresentationArea className="d-flex justify-content-center">
                {!isFileFetching && filesChallenge.presentationVideo !== undefined ? (
                  <PresentationVideo src={filesChallenge.presentationVideo.path} controls />
                ) : (
                  <p></p>
                )}
              </PresentationArea>
            </Col>
          </Row>
        </Col>
      </Row>
    </ScreenContainer>
  )
}
