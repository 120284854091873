import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { trans } from 'sharedKernel/i18n'
import {
  STATUS_COLUMN_INDEX
} from './contracts'
import { HubTable } from 'components/HubDefaultTable'
import { COMPANY_EVALUATION_INVITE_STATUS } from 'sharedKernel/constants'

export interface HubCompanyEvaluatorsInviteTableProps {
  header: string[]
  rows: string[][]
}

export const HubCompanyEvaluatorsInviteTable = ({ header, rows }: HubCompanyEvaluatorsInviteTableProps): JSX.Element => {
  const status = {}
  status[COMPANY_EVALUATION_INVITE_STATUS.ACCEPTED] = { color: 'green', label: trans('Aceito') }
  status[COMPANY_EVALUATION_INVITE_STATUS.INVITED] = { color: 'orange', label: trans('Convidado') }
  status[COMPANY_EVALUATION_INVITE_STATUS.ERROR] = { color: 'red', label: trans('Erro') }
  status[COMPANY_EVALUATION_INVITE_STATUS.REFUSED] = { color: 'red', label: trans('Recusado') }
  status[COMPANY_EVALUATION_INVITE_STATUS.EXPIRED] = { color: 'gray', label: trans('Expirado') }
  status[COMPANY_EVALUATION_INVITE_STATUS.CANCELED] = { color: 'gray', label: trans('Cancelado') }

  const getColumn = (column: any, columnIndex: number): JSX.Element => {
    if (columnIndex === STATUS_COLUMN_INDEX) {
      return (
        <>
          <FontAwesomeIcon className="pr-2" color={status[column]?.color} icon={['fas', 'circle']} />
          {status[column]?.label}
        </>
      )
    }
    return column
  }

  const getRows = (): JSX.Element[][] => {
    const styledRows = rows.map(row => {
      return (
        row.map((column, columnIndex) => {
          return (
            getColumn(column, columnIndex)
          )
        })
      )
    })
    return styledRows
  }

  const headerSize = ['33%', '42%', '25%']

  return (
    <HubTable header={header} rows={getRows()} headerSize={headerSize} />
  )
}
