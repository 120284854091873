/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { BaseTag } from 'iebt-zing-storybook'
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { trans } from 'sharedKernel/i18n'
import { changeSolution, store, TReducers } from 'store'
import { Navigation, Keyboard } from 'swiper'
import { SwiperSlide } from 'swiper/react'
import { bankersRound, calculateScore } from '../../idea/evaluate/IdeaApplicationForm/IdeaEvaluation/behavior'
import {
  ButtonArea,
  LikertScaleRange,
  Questions,
  QuestionsContainer,
  SectionTitleHeader,
  SectionTitleText,
  SectionTitleWrapper,
  TextArea,
  LabelTextArea
} from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tippy from '@tippyjs/react'
import { followCursor } from 'tippy.js'

export interface EvaluationModalProps {
  disabled: boolean
}
export const EvaluationModal = ({ disabled }: EvaluationModalProps): JSX.Element => {
  const labels = {
    changeGrade: trans('Deseja alterar a nota?'),
    actualGrade: trans('Nota atual'),
    newGrade: trans('Nova nota'),
    clear: trans('Limpar'),
    labelComments: trans('Comentários por seção (Opcional)'),
    labelFeedback: trans('Feedback geral'),
    commentsPlaceHolder: trans('O seu comentário será visualizado apenas pela empresa organizadora do desafio!'),
    feedback: trans('Feedback')
  }
  const storeState = store.getState()
  const evaluation = storeState.solutionReducer.data.evaluation

  const getEvaluationPoints = (): string => {
    const generalScore = calculateScore(evaluation)
    const roundGeneralScore = bankersRound(generalScore)
    return (roundGeneralScore).toFixed(2)
  }

  const evaluationPoints = getEvaluationPoints()

  // Props a serem recebidas da api posteriormente
  const SCALE_QUANTITY = 6
  const MAX_SCALE_VALUE = 5

  const [actualSlide, setActualSlide] = useState(0)
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const handleChange = (element, index): void => {
    evaluation.sections[actualSlide].answers[index].value = element.target.value
    store.dispatch(changeSolution(evaluation))
    forceUpdate()
  }
  const handleCommentsChange = (element): void => {
    evaluation.sections[actualSlide].comments = element.value
    store.dispatch(changeSolution(evaluation))
    forceUpdate()
  }

  const handleFeedbackChange = (element): void => {
    evaluation.comments = element.value
    store.dispatch(changeSolution(evaluation))
    forceUpdate()
  }

  const handleClick = (index): void => {
    evaluation.sections[actualSlide].answers[index].value = null
    store.dispatch(changeSolution(evaluation))
    forceUpdate()
  }

  const scaleRefs = useRef<HTMLInputElement[]>([])
  const commentsRefs = useRef<HTMLTextAreaElement[]>([])
  const feedback = useRef<HTMLTextAreaElement>()
  const btnsRefs = useRef<HTMLButtonElement[]>([])

  useEffect(() => {
    scaleRefs.current.forEach((element, elementIndex) => {
      if (element != null) {
        element.addEventListener('input', (ele) => {
          handleChange(ele, elementIndex)
        })
      }
    })

    btnsRefs.current.forEach((element, elementIndex) => {
      if (element != null) {
        element.addEventListener('click', () => {
          handleClick(elementIndex)
        })
      }
    })

    commentsRefs.current.forEach((element, elementIndex) => {
      if (element != null) {
        element.addEventListener('change', () => {
          handleCommentsChange(element)
        })
      }
    })
    if (feedback.current !== undefined && feedback.current !== null) {
      feedback.current.addEventListener('change', () => {
        handleFeedbackChange(feedback.current)
      })
    }
  }, [scaleRefs, actualSlide])

  return (
    <Row className="px-4">
      <Col>{labels.changeGrade}</Col>
      <Row className="pb-2">
        <Col>{labels.actualGrade}</Col>
        <Col>
          <BaseTag tagContent={evaluation.points.toFixed(2)} variant="primary-invert" />
        </Col>
        <Col>{labels.newGrade}</Col>
        <Col>
          {/* A lógica do calculo não foi definida pois cada questão tera um tipo de peso */}
          <BaseTag tagContent={evaluationPoints} variant="primary-invert" />
        </Col>
      </Row>
      <Row>
        <SectionTitleWrapper
          keyboard={{
            enabled: true
          }}
          onSlideChange={(swiper) => {
            setActualSlide(swiper.activeIndex)
          }}
          slidesPerView={1}
          modules={[Navigation, Keyboard]}
          navigation={true}
          allowSlideNext={true}
          mousewheel={false}
          grabCursor={false}
        >
          {evaluation.sections.map((section, sectionIndex) => {
            return (
              <SwiperSlide key={`swiperSlide.${String(sectionIndex)}`}>
                <SectionTitleHeader>
                  <SectionTitleText>{section.name}</SectionTitleText>
                </SectionTitleHeader>
              </SwiperSlide>
            )
          })}
           <SwiperSlide key={`swiperSlide.${String(evaluation.sections.length)}`}>
              <SectionTitleHeader>
                <SectionTitleText>{labels.feedback}</SectionTitleText>
              </SectionTitleHeader>
            </SwiperSlide>
        </SectionTitleWrapper>
      </Row>
      {actualSlide < evaluation.sections.length
        ? (
        <QuestionsContainer className="pt-2">
          {evaluation.sections[actualSlide].answers.map((answer, answerIndex) => {
            return (
              <Row className="py-2" key={answer.questionId}>
                <Questions className="my-auto" sm={5}>
                  {`${Number(answer.order) + 1}. `}
                  {answer.question}
                  {answer.description !== ''
                    ? <Tippy
                    content={answer.description}
                    placement='left'
                    followCursor={true}
                    plugins={[followCursor]}
                    animation="scale"
                    arrow={false}
                    theme="primary"
                    zIndex={10002}
                  >
                  <span>
                    <FontAwesomeIcon
                      icon={['fas', 'circle-info']}
                      className="ml-1"
                    />
                  </span>
                  </Tippy>
                    : <></>
                }
              </Questions>
                <Col className="my-auto">
                  <Row className="d-flex container-fluid">
                    <Col sm={9}>
                      <LikertScaleRange>
                        <input
                          key={answerIndex}
                          disabled={disabled}
                          list="markers"
                          className={answer.value === null ? 'inactive' : 'active'}
                          type="range"
                          min="1"
                          max={MAX_SCALE_VALUE}
                          step="1"
                          value={answer.value === null ? 0 : answer.value}
                          ref={(element) => {
                            scaleRefs.current[answerIndex] = element as HTMLInputElement
                          }}
                        />
                        <datalist id="markers">
                          {[...Array(SCALE_QUANTITY).keys()].map((value, valueIndex) => {
                            return <option key={`likertScaleOption.${valueIndex}`} value={value} />
                          })}
                        </datalist>
                      </LikertScaleRange>
                    </Col>
                    <Col sm={1}>{answer.value}</Col>

                    <Col sm={1}>
                      <ButtonArea>
                        <button
                          ref={(element) => {
                            btnsRefs.current[answerIndex] = element as HTMLButtonElement
                          }}
                          disabled={disabled}
                        >
                          {labels.clear}
                        </button>
                      </ButtonArea>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )
          })}
          <div className="d-flex flex-column align-items-start pt-2 pb-2">
          {evaluation.sections.map((section, sectionIndex) => {
            if (sectionIndex === actualSlide) {
              return (
                <>
                  <LabelTextArea>{labels.labelComments}</LabelTextArea>
                  <TextArea
                    key={`sectionComments.${sectionIndex}`}
                    name="comments"
                    value={section.comments}
                    placeholder={labels.commentsPlaceHolder}
                    ref={(element) => {
                      commentsRefs.current[actualSlide] = element as HTMLTextAreaElement
                    }}
                    disabled={disabled}
                  ></TextArea>
                </>
              )
            }
            return <div key={`sectionComments.${sectionIndex}`}></div>
          })}
          </div>
        </QuestionsContainer>
          )
        : (
        <QuestionsContainer className="pt-2">
            <LabelTextArea>{labels.labelFeedback}</LabelTextArea>
            <TextArea
              key={`sectionComments.${evaluation.sections.length}`}
              name="comments"
              value={evaluation.comments}
              placeholder={labels.commentsPlaceHolder}
              ref={(element) => {
                feedback.current = element as HTMLTextAreaElement
              }}
              disabled={disabled}
              ></TextArea>
        </QuestionsContainer>
          )}
    </Row>
  )
}
