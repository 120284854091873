import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BaseButton } from 'iebt-zing-storybook'
import { Col } from 'react-bootstrap'

import { hasTextMatch } from 'sharedKernel/handlers'
import { trans } from 'sharedKernel/i18n'
import { IEvaluator } from './contracts'
import { SolutionContent, TitleContainer } from './styles'
import { HubTable } from 'components/HubDefaultTable'

export interface HubCompanyEvaluatorsChallengeTableProps {
  evaluators: Evaluators
  searchTerm: string
  showEditModal: (evaluator: any, solutionList: string[]) => void
}

interface Evaluators {
  rows: any[]
}

export const NO_CONTENT_EVALUATORS_MESSAGE = 'Ainda não há avaliadores para atribuição'

export const HubCompanyEvaluatorsAssignTable = ({ evaluators, searchTerm, showEditModal }: HubCompanyEvaluatorsChallengeTableProps): JSX.Element => {
  const labels = {
    evaluator: trans('Avaliador'),
    solution: trans('Solução'),
    actions: trans('Ações'),
    noEvaluators: trans(NO_CONTENT_EVALUATORS_MESSAGE)
  }

  const headerSize = ['46%', '44%', '10%']

  const tableHeader = (): any => {
    return [
      labels.evaluator,
      labels.solution,
      labels.actions
    ]
  }

  const buildEvaluatorRows = (): any[] => {
    const initialRows = evaluators.rows.map((evaluator: IEvaluator, index) => {
      return buildEvaluatorColumns(evaluator)
    })

    const filteredRows: any[] = []
    initialRows.forEach(row => {
      if ((hasTextMatch(row[0].props.children, searchTerm)) || hasTextMatch(row[1].props.children, searchTerm)) {
        filteredRows.push(row)
      }
    })

    return filteredRows
  }

  const buildEvaluatorColumns = (evaluator: IEvaluator): any[] => {
    const evaluatorColumns: any = []
    const solutionList: string[] = []
    let solutionTitles = '-'

    evaluatorColumns.push(getEvaluatorName(evaluator))

    evaluator.resolvers.forEach((resolver) => {
      resolver.solutions.forEach((solution, solutionIndex) => {
        if (solutionIndex === 0) {
          solutionTitles = solution.title
        }
        if (solutionIndex > 0) {
          solutionTitles = solutionTitles + `\n${solution.title}`
        }
        solutionList.push(solution.id)
      })
    })

    evaluatorColumns.push(getSolutionColumn(solutionTitles))
    evaluatorColumns.push(getActionIcons(evaluator, solutionList))

    return evaluatorColumns
  }

  const getEvaluatorName = (evaluator: IEvaluator): JSX.Element => {
    return (
      <TitleContainer>{evaluator.name}</TitleContainer>
    )
  }

  const getSolutionColumn = (content: any): JSX.Element => {
    return (
      <SolutionContent>{content}</SolutionContent>
    )
  }

  const getActionIcons = (evaluator: IEvaluator, solutionList: string[]): JSX.Element => {
    return (
      <Col className="d-flex align-items-center justify-content-start gap-2">
        <BaseButton
          btnContent={<FontAwesomeIcon icon={['fas', 'pen']} />}
          variant="primary-invert"
          size="icon"
          onClick={() => { showEditModal(evaluator, solutionList) }}
        />
        {/* MANTER BOTÃO OCULTO POR ENQUANTO
        <BaseButton
          btnContent={<FontAwesomeIcon icon={['fas', 'trash']} />}
          variant="primary-invert"
          size="icon"
          onClick={() => {
            // A fazer
          }}
        /> */}
      </Col>
    )
  }

  return (
    <>
    {
      evaluators === null || evaluators === undefined || evaluators.rows.length === 0
        ? <div className="d-flex justify-content-center">{labels.noEvaluators}</div>
        : <HubTable
            header={tableHeader()}
            rows={buildEvaluatorRows()}
            headerSize={headerSize} />
    }
    </>
  )
}
